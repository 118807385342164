.author-elem {
    cursor: pointer;
    background-color: white;
}

.author-elem:hover {
    cursor: pointer;
    background-color: lightgray;
}

.clickableText {
    color: blue;
    font-style: oblique;
    cursor: pointer;
}